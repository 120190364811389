import Private from "../../hoc/Privet";
import LayoutAdmin from "../../layout";
import SignInPage from "../../containers/SignIn";




const SignIn = Private(SignInPage);


export default SignIn;