export enum PERMISSIONS {
  // DASHBOARD
  'DASHBOARD_READ' = 'DASHBOARD_READ',
  'DASHBOARD_UPDATE' = 'DASHBOARD_UPDATE',
  'DASHBOARD_CREATE' = 'DASHBOARD_CREATE',
  'DASHBOARD_DELETE' = 'DASHBOARD_DELETE',
  'DASHBOARD_PERMANENT_DELETE' = 'DASHBOARD_PERMANENT_DELETE',

  // PROJECTS
  'PROJECTS_READ' = 'PROJECTS_READ',
  'PROJECTS_UPDATE' = 'PROJECTS_UPDATE',
  'PROJECTS_CREATE' = 'PROJECTS_CREATE',
  'PROJECTS_DELETE' = 'PROJECTS_DELETE',

  // CALENDAR
  'CALENDAR_READ' = 'CALENDAR_READ',
  'CALENDAR_UPDATE' = 'CALENDAR_UPDATE',
  'CALENDAR_CREATE' = 'CALENDAR_CREATE',
  'CALENDAR_DELETE' = 'CALENDAR_DELETE',

  // VACATIONS
  'VACATIONS_READ' = 'VACATIONS_READ',
  'VACATIONS_UPDATE' = 'VACATIONS_UPDATE',
  'VACATIONS_CREATE' = 'VACATIONS_CREATE',
  'VACATIONS_DELETE' = 'VACATIONS_DELETE',

  // EMPLOYEES
  'EMPLOYEES_READ' = 'EMPLOYEES_READ',
  'EMPLOYEES_UPDATE' = 'EMPLOYEES_UPDATE',
  'EMPLOYEES_CREATE' = 'EMPLOYEES_CREATE',
  'EMPLOYEES_DELETE' = 'EMPLOYEES_DELETE',

  // MESSENGER
  'MESSENGER_READ' = 'MESSENGER_READ',
  'MESSENGER_UPDATE' = 'MESSENGER_UPDATE',
  'MESSENGER_CREATE' = 'MESSENGER_CREATE',
  'MESSENGER_DELETE' = 'MESSENGER_DELETE',

  // INFO PORTAL
  'INFO_PORTAL_READ' = 'INFO_PORTAL_READ',
  'INFO_PORTAL_UPDATE' = 'INFO_PORTAL_UPDATE',
  'INFO_PORTAL_CREATE' = 'INFO_PORTAL_CREATE',
  'INFO_PORTAL_DELETE' = 'INFO_PORTAL_DELETE',
}


export enum ROLES{
  SYSTEM_USER = "System User",
  RAE_SUPER_ADMIN = "RAE Super Admin",
  RAE_RESEARCH_ADMIN = "RAE Research Admin",
  CUSTOMER_ADMIN = "Customer Admin",
  ORGANIZATION_ADMIN = "Organization Admin",
  LOCATION_ADMIN = "Location Admin",
  CASE_MANAGER = "Case Manager",
  ORDERING_PROVIDER = "Ordering Provider",
  CLINICAL_STAFF = "Clinical Staff",
  PEER_PORTAL_USER = "Peer Portal User",
  APP_USER = "App User",
}

